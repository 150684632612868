import { format } from "d3";
import UrlManager from '../utils/store/lib/url_manager';

function createChart($el, data, xName, yName) {
  const options = {
    series: [{
      data: data.map(e => [+e.x, +e.y, +e.size, +e.inputs])
    }],
    chart: {
      type: "bubble",
      height: "100%",
      animations: {
        enabled: false
      },
      toolbar: {
        show: false
      }
    },
    colors: ['var(--bs-blue)'],
    dataLabels: {
        enabled: false
    },
    plotOptions: {
      bubble: {
        minBubbleRadius: 5
      }
    },
    grid: {
      xaxis: {
        lines: {
          show: true
        }
      },
      yaxis: {
        lines: {
          show: true
        }
      }
    },
    markers: {
      strokeWidth: 1,
      onClick: (e) => {
        const dataPointIndex = $(e.target).attr("j");
        const dataPoint = data[dataPointIndex];

        $.ajax({
          url: dataPoint.url,
          method: "GET",
          data: { x: dataPoint.x,
                  y: dataPoint.y,
                  xMetric: dataPoint.x_metric,
                  yMetric: dataPoint.y_metric },
          success: ((html) => {
            $("#input-counts-table").html(html);
            $("#filter-x").text(dataPoint.x)
            $("#filter-y").text(dataPoint.y)
            $("#metric-filters").removeClass("d-none")
          }),
        });
      }
    },
    xaxis: {
      type: "numeric",
      min: -100,
      max: 1100,
      tickAmount: 12,
      tooltip: {
        enabled: false
      },
      labels: {
        formatter: format(",")
      },
      title: {
        text: xName
      }
    },
    yaxis: {
      min: -100,
      max: 1100,
      tickAmount: 12,
      labels: {
        formatter: format(",")
      },
      title: {
        text: yName
      }
    },
    tooltip: {
      custom: ({ dataPointIndex }) => {
        const dataPoint = data[dataPointIndex];

        return `
          <div class="apexcharts-tooltip-title">
            <strong class="me-3">${xName}</strong> ${format(",")(dataPoint.x)} <br>
            <strong class="me-3">${yName}</strong> ${format(",")(dataPoint.y)}
          </div>
          <div class="apexcharts-tooltip-series-group apexcharts-active">
            <dl class="apexcharts-tooltip-text row no-gutters mb-0">
              <dt class="col-6 pe-1">Input Count</dt>
              <dd class="col-6 ps-1">${dataPoint.size}</dd>

              <dt class="col-6 pe-1">Click to see inputs</dt>
            </dl>
          </div>
        `
      }
    }
  };

  const chart = new ApexCharts($el.get(0), options);

  chart.render();
  $(document).one("turbo:before-cache", () => chart.destroy());
}

function loadScatterPlot() {
  const $el = $("#compare-metrics-scatter-plot");
  let url = $el.data("url")

  let inputUrl = $el.data("input-url")
  const xMetric = $("#compare-metrics-scatter-plot-x-axis").val()
  const yMetric = $("#compare-metrics-scatter-plot-y-axis").val()
  const xName = $(`#compare-metrics-scatter-plot-x-axis option[value="${xMetric}"]`).text()
  const yName = $(`#compare-metrics-scatter-plot-y-axis option[value="${yMetric}"]`).text()
  const params = { x: xMetric, y: yMetric }

  if ($el.length) {
    updateUrl(xMetric, yMetric)
    resetTable(inputUrl);
    $.getJSON(url, params, (values) => {
      $("#compare-metrics-scatter-plot").html("");
      createChart($el, values, xName, yName)
    });
  }
}

function updateUrl(x, y) {
  const urlManager = new UrlManager();
  urlManager.addOrModify('x', x);
  urlManager.addOrModify('y', y);
}

function resetTable(url) {
  $.ajax({
    url: url,
    method: "GET",
    success: ((html) => {
      $("#input-counts-table").html(html);
    }),
  });
}

function registerAxisSelectorListeners() {
  $("#compare-metrics-scatter-plot-x-axis, #compare-metrics-scatter-plot-y-axis").each((_, el) => {
    el.addEventListener("change", () => {
      loadScatterPlot();
    })
  });
}

function setDifferentMetrics() {
  const $ySelector = $('#compare-metrics-scatter-plot-y-axis')
  $ySelector.val($('#compare-metrics-scatter-plot-y-axis option')[1].value);
}

$(document).on("turbo:load", function() {
  const $el = $("#compare-metrics-scatter-plot-page");

  if ($el.length) {
    setDifferentMetrics();
    loadScatterPlot();
    registerAxisSelectorListeners();
  }
});

$(document).on("click", "#filter-reset", (e) => {
  const $el = e.target
  const inputUrl = $el.dataset.inputUrl
  $("#metric-filters").addClass("d-none")
  resetTable(inputUrl)
})
